<template>
  <div class="block w-full">
    <div
      class="flex items-center justify-between border-b border-neutral-800/10 pb-[20px] pt-[14px] last:border-none">
      <div class="flex min-w-0 items-center gap-[18px] whitespace-nowrap">
        <img
          class="aspect-square w-[47px] rounded-xl bg-[#56575A]"
          :src="useApiAsset(program.image as string)"
          :alt="program.radio?.name" />
        <div
          @click="
            $router.push(
              localePath({
                name: 'radio-programs-id',
                params: { id: program.id }
              })
            )
          "
          class="relative flex w-full cursor-pointer flex-col items-start truncate text-left">
          <p class="relative w-full truncate text-sm font-bold">
            {{ programTranslations?.title }}
          </p>
          <p class="text-sm font-normal">
            {{
              translateItem(
                program.categories_boa[0]?.category_boa_id?.translations
              )?.name
            }}
          </p>
          <button
            @click.stop="() => handleModal(i)"
            :style="{ color: primaryColor ?? '#1C1C27' }"
            type="button"
            class="flex items-center text-xs font-medium">
            {{ $t('Embedded.read-the-program-description') }}
            <Icon
              name="arrow-right-s-line"
              class="pl-3" />
          </button>
        </div>
      </div>
      <NuxtLink
        :to="
          localePath({ name: 'radio-programs-id', params: { id: program.id } })
        "
        class="relative flex w-fit cursor-pointer select-none items-center gap-2 whitespace-nowrap rounded-xl bg-neutral-800 px-[18px] py-[14px] text-xs font-medium text-white">
        <p>
          {{ $t('Embedded.see-details') }}
        </p>
        <Icon name="arrow-right-s-line" />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translateItem } from '@/composables/use-translations'

type Props = {
  program: Program
  i: number
  primaryColor: string
  handleModal: (i: number) => void
}

const props = defineProps<Props>()

const programTranslations = computed(() => {
  return translateItem(props.program)
})
</script>
